<template>
  <div>
    <div v-if="$route.params.ID > 0">
      <feather-icon
        icon="ArrowLeftIcon"
        svgClasses="h-8"
        style="cursor: pointer"
        @click="$router.push('/Doctor')"
      />

      <doctor-Tab class="mt-4" :mainTabName="'AvailabilitySchedule'" />
    </div>
    <div
      class="vx-row mb-3"
      v-if="
        $acl.check('adminaccountmanager') && $store.state.AppActiveUser.IsWrite
      "
    >
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-left">
          <vs-button class="mr-6" color="green" @click="goToAddAvailability">{{
            $t("Add Availability")
          }}</vs-button>

          <vs-button class="mr-6" @click="goToEditAvailability">{{
            $t("Edit Availability")
          }}</vs-button>

           <p class="mr-6" style="font-weight: bold; color: #454a62" v-if="DoctorSessions !=null && DoctorSessions.length>0">
            {{ $t("DoctorPrice") }} :
            {{
              DoctorSessions[midOfDoctorSessionList].Price 
            }}
            {{ DoctorSessions[midOfDoctorSessionList].Currency.SymbolEN }}
          </p>
        </div>
      </div>
    </div>

    <template
      v-if="
        this.$store.state.AvailabilityScheduleList.currentItemView ==
        'item-grid-view'
      "
    >
      <FullCalendar
        defaultView="dayGridMonth"
        ref="fullCalendar"
        selectable="false"
        :disabled="true"
        :options="configOptions"
        editable="false"
      />
    </template>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/vdom"; // solve problem with Vite
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import doctorTab from "@/views/tabs/doctorTab.vue";

// import Datepicker from "vuejs-datepicker";
// import SlotDetails from "./SlotDetails";
import moduleAvailabilitySchedule from "@/store/AvailabilitySchedule/moduleAvailabilitySchedule.js";
import _color from "@/assets/utils/color.js";

export default {
  components: {
    doctorTab,
    FullCalendar,
    dayGridPlugin,
    listPlugin,
    interactionPlugin,
    timeGridPlugin,
  },
  data() {
    return {
      calendarPlugins: [
        dayGridPlugin,
        interactionPlugin,
        //  listPlugin,
        timeGridPlugin,
      ],
      doctorModel: {},
      midOfDoctorSessionList: 0,
      SearchModel: { DoctorID: this.$route.params.ID, SetPrice: true },
      DoctorSessions: [],
      confirmPeriod: {},
      format: "dd-MM-yyyy",
      // slot details (sidebar)
      addNewDataSidebar: false,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false,
      firstTime: true,
      itemsPerPage: 10,
      selected: [],
      currentItemView: "item-grid-view",
      showSearchSidebar: false,
      search: {},
      event: {},
      isMounted: false,
      showTable: true,
    };
  },

  methods: {
    formatJsDate(dateToFormat) {
      debugger;
      return (
        dateToFormat.getFullYear() +
        "-" +
        (dateToFormat.getMonth() + 1) +
        "-" +
        dateToFormat.getDate()
      );
    },
    handleMonthChange(arg) {
      if (this.calendarStart != arg.startStr) {
        this.calendarStart = arg.startStr;
        this.SearchModel.DateFrom = this.formatJsDate(arg.view.activeStart);
        this.SearchModel.DateTo = this.formatJsDate(arg.view.activeEnd);
        this.getDoctorSessions(this.SearchModel);
      }
    },
    test() {
      debugger;
      this.DoctorSessions[this.midOfDoctorSessionList].CurrencyID;
      debugger;
    },
    getDoctorSessions(model) {
      this.$vs.loading();
      this.DoctorSessions=[];
      debugger;
      this.$store
        .dispatch("DoctorList/SimpleSearchDoctorSessions", model)
        .then((res) => {
          this.DoctorSessions = res.data.Data;
          debugger
          this.midOfDoctorSessionList = this.DoctorSessions.length;
          if (this.DoctorSessions.length % 2 == 0) {
            this.midOfDoctorSessionList = this.midOfDoctorSessionList / 2;
          } else {
            this.midOfDoctorSessionList = (this.midOfDoctorSessionList + 1) / 2;
          }
          this.test();
          debugger;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    goToAddAvailability() {
      this.$router.push({
        name: "DoctorAddAvailability",
        params: { ID: this.$route.params.ID },
      });
    },
    goToEditAvailability() {
      this.$router.push({
        name: "DoctorEditAvailability",
        params: { ID: this.$route.params.ID },
      });
    },

    // searchAnnualPlan() {
    //   this.$vs.loading();
    //   this.$store.dispatch("AvailabilityScheduleList/SearchAnnualPlan",this.search).then(res=>{
    //     this.DoctorSessions = res.data.Data;
    //       this.$vs.loading.close();

    //   }).catch(()=>{
    //       this.$vs.loading.close();
    //   });
    // },
  },

  created() {
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleAvailabilitySchedule.isRegistered) {
      this.$store.registerModule(
        "AvailabilityScheduleList",
        moduleAvailabilitySchedule
      );
      moduleAvailabilitySchedule.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.search.DoctorID = ID;
      this.$store.dispatch("DoctorList/GetDoctor", ID).then((res) => {
        this.doctorModel = res.data.Data;
        this.$store.commit("SET_SetTitle", "Dr. " + this.doctorModel.Name);
      });
      // this.searchAnnualPlan();
    } else {
      // this.searchAnnualPlan();
    }
  },

  computed: {
    configOptions() {
      return {
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events: this.DoctorSessions,
        weekends: this.weekendsVisible,
        datesSet: this.handleMonthChange,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        initialView: "dayGridMonth",
      };
    },
    isConfirmFormValid() {
      return this.confirmPeriod.dateFrom && this.confirmPeriod.dateTo;
    },
    events() {
      return this.$store.state.AvailabilityScheduleList.annualPlans;
    },
    currentPage() {
      if (this.isMounted) {
        // this.showTable = false;
        return this.$refs.tableAnnualPlan.currentx;
      }
      return 0;
    },

    annualPlans() {
      return this.$store.state.AvailabilityScheduleList.annualPlanList;
    },
  },
  mounted() {
    var vm = this;

    vm.$refs.fullCalendar.getApi().next();
         
            
    setTimeout(function () {
       //if(vm.DoctorSessions==null||vm.DoctorSessions.length==0)
        {
               vm.$refs.fullCalendar.getApi().prev();
        }
    }, 500);
  },
};
</script>



<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
